import { Button, Modal } from 'antd';
import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import HeadUpIcon from '../../assets/HeadUpIcon.svg';
import congratulaionIcon from '../../assets/Congratulations.svg';
import OhNoIcon from '../../assets/OhNoIcon.svg';
import { useSendTaskMutation } from '../../api/tenders';
import ScoreSubmittedModal from '../../assets/ScoreSubmittedModal.svg';
import '../../components/Modals/Modals.scss';
import { tenderDataProps } from '../../pages/TenderViewPage/types';
import { Loader } from '../Loader/Loader';
import moment from 'moment';

interface HeadsUpI {
    openModal: boolean;
    setOpenModal: Dispatch<SetStateAction<boolean>>;
    data: tenderDataProps;
    setTrigger: Dispatch<SetStateAction<boolean>>;
}

export const CompletionResultModal: FC<HeadsUpI> = ({ openModal, setOpenModal, data, setTrigger }) => {
    const [sendTask, { isLoading: isLoadingSendTask, isSuccess: isSuccessSent }] = useSendTaskMutation();
    const [step, setStep] = useState(1);

    const deadline = moment(data.tasks_deadline_at);
    const today = moment();
    const isWithinDeadline = today.isSameOrBefore(deadline, 'day');

    const isTwoModals =
        data?.phase_tasks?.[0]?.action === 'define_phase_questionnaire' ||
        (data?.phase_tasks?.[0]?.action === 'supplier_scorecard' && data.phase === 'evaluate_3') ||
        (data.phase === 'evaluate_4' && data?.is_show_multi_award);

    console.log('isWithinDeadline', isWithinDeadline);
    console.log('isTwoModals', isTwoModals);

    const sendTaskFunc = async () => {
        let formData = {
            id: data?.id
        };
        try {
            await sendTask(formData).unwrap();
            await setTrigger((prev: boolean) => !prev);
        } catch {}
    };

    useEffect(() => {
        if (isSuccessSent) {
            setOpenModal(false);
        }
    }, [isSuccessSent]);

    return (
        <Modal
            className="confirmModal"
            centered
            open={openModal}
            closeIcon={<div style={{ display: 'none', cursor: 'context-menu' }}></div>}
            width={600}
            footer={<></>}
        >
            {isTwoModals ? (
                <>
                    {isWithinDeadline ? (
                        <div className="modal">
                            {step === 1 && (
                                <div className="modal__main main" style={{ padding: '24px 56px 43px 56px' }}>
                                    <div className="main__title" style={{ margin: '0 0 6px 0', textAlign: 'start' }}>
                                        <div>
                                            <img src={congratulaionIcon} className="main__icon" />
                                            <div className="main__title-no-head">Congratulations</div>
                                        </div>
                                        Great job completing your task on time! You've earned XX extra days for your
                                        next task.
                                        <br />
                                        <br />
                                        Keep up the fantastic work!
                                    </div>
                                </div>
                            )}
                            {step === 2 && (
                                <>
                                    {data?.phase === 'define' && (
                                        <div className="modal__main main" style={{ padding: '24px 56px 43px 56px' }}>
                                            <div
                                                className="main__title"
                                                style={{ margin: '0 0 6px 0', textAlign: 'center' }}
                                            >
                                                <div>
                                                    <img src={HeadUpIcon} className="main__icon" />
                                                    <div className="main__title-no-head">
                                                        Heads Up! We’ll Be in Touch Soon!
                                                    </div>
                                                </div>
                                                As part of the Define Phase, we’ll be scheduling a meeting to ask
                                                follow-up questions and ensure we are aligned on your tender
                                                requirements.
                                                <br />
                                                <br />
                                                Watch out for a meeting request soon!
                                            </div>
                                        </div>
                                    )}
                                    {data?.phase_tasks?.[0]?.action === 'supplier_scorecard' &&
                                        data.phase === 'evaluate_3' && (
                                            <div className="modal">
                                                <div
                                                    className="modal__main main"
                                                    style={{ padding: '24px 56px 43px 56px' }}
                                                >
                                                    <div
                                                        className="main__title"
                                                        style={{ margin: '0 0 6px 0', textAlign: 'center' }}
                                                    >
                                                        <div>
                                                            <img src={ScoreSubmittedModal} className="main__icon" />
                                                            <div className="main__title-no-head">
                                                                Scores Submitted – What’s Next?
                                                            </div>
                                                        </div>
                                                        Thank you for submitting your scores! We’re now compiling the
                                                        results and will send you an updated Supplier Report Card soon.
                                                        <br />
                                                        <br />
                                                        <b>Get your adjudication committee ready</b> - you’ll be
                                                        reviewing the updated report card and making your shortlist
                                                        decisions.
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    {data.phase === 'evaluate_4' && data?.is_show_multi_award && (
                                        <div className="modal">
                                            <div className="modal__header meeting" style={{ height: '88px' }}>
                                                <div className="modal__title name-title">
                                                    Managing Supplier Transitions
                                                </div>
                                            </div>

                                            <div
                                                className="modal__main main"
                                                style={{ padding: '24px 56px 43px 56px' }}
                                            >
                                                <div className="main__title" style={{ margin: '0 0 6px 0' }}>
                                                    Unsuccessful suppliers will only be notified once the agreement with
                                                    your appointed supplier has been finalised.
                                                    <br />
                                                    <br />
                                                    However, if your appointed supplier is different from your incumbent
                                                    supplier, we recommend scheduling a meeting with your incumbent
                                                    supplier as a courtesy. This provides an opportunity for them to
                                                    assist in ensuring a smooth transition before formal notifications
                                                    are sent.
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </>
                            )}
                            <div className="multipleModals">
                                <div className="multipleModals__steps">
                                    <div className={`multipleModals__step`}></div>
                                    <div className={`multipleModals__step ${step === 1 ? 'inactive' : ''}`}></div>
                                </div>
                                {step === 1 && (
                                    <Button
                                        className={`tasks__next-btn`}
                                        style={{ width: '120px' }}
                                        type="primary"
                                        onClick={() => {
                                            setStep(2);
                                        }}
                                    >
                                        Next
                                    </Button>
                                )}
                                {step === 2 && (
                                    <div style={{ display: 'flex' }}>
                                        <Button
                                            className={`tasks__next-btn`}
                                            style={{ width: '120px', margin: '0 20px 0 0' }}
                                            onClick={() => {
                                                setStep(1);
                                            }}
                                        >
                                            Previous
                                        </Button>
                                        <Button
                                            className={`tasks__next-btn`}
                                            style={{ width: '120px' }}
                                            type="primary"
                                            onClick={() => {
                                                if (isLoadingSendTask) return;
                                                sendTaskFunc();
                                            }}
                                        >
                                            {isLoadingSendTask ? <Loader /> : 'Got it'}
                                        </Button>
                                    </div>
                                )}
                            </div>
                        </div>
                    ) : (
                        <div className="modal">
                            <div className="modal__main main" style={{ padding: '24px 56px 43px 56px' }}>
                                <div className="main__title" style={{ margin: '0 0 6px 0', textAlign: 'center' }}>
                                    <div>
                                        <img src={HeadUpIcon} className="main__icon" />
                                        <div className="main__title-no-head">Heads Up! We’ll Be in Touch Soon!</div>
                                    </div>
                                    As part of the Define Phase, we’ll be scheduling a meeting to ask follow-up
                                    questions and ensure we are aligned on your tender requirements.
                                    <br />
                                    <br />
                                    Watch out for a meeting request soon!
                                </div>
                            </div>
                        </div>
                    )}
                </>
            ) : (
                ''
            )}
            {!isTwoModals ? (
                isWithinDeadline ? (
                    <>
                        <div className="modal">
                            <div className="modal__main main" style={{ padding: '24px 56px 43px 56px' }}>
                                <div className="main__title" style={{ margin: '0 0 6px 0', textAlign: 'start' }}>
                                    <div>
                                        <img src={congratulaionIcon} className="main__icon" />
                                        <div className="main__title-no-head">Congratulations</div>
                                    </div>
                                    Great job completing your task on time! You've earned XX extra days for your next
                                    task.
                                    <br />
                                    <br />
                                    Keep up the fantastic work!
                                </div>
                            </div>
                            <Button
                                className={`tasks__next-btn`}
                                style={{ width: '487px', margin: '0 57px' }}
                                type="primary"
                                onClick={() => {
                                    if (isLoadingSendTask) return;
                                    sendTaskFunc();
                                }}
                            >
                                {isLoadingSendTask ? <Loader /> : 'Got it'}
                            </Button>
                        </div>
                    </>
                ) : (
                    <div className="modal">
                        <div className="modal__main main" style={{ padding: '24px 56px 43px 56px' }}>
                            <div className="main__title" style={{ margin: '0 0 6px 0', textAlign: 'start' }}>
                                <div>
                                    <img src={OhNoIcon} className="main__icon" />
                                    <div className="main__title-no-head">Oh, no</div>
                                </div>
                                Your supplier appointment has been moved out due to the delay. But don’t be discouraged
                                - you can still catch up by completing your upcoming tasks earlier.
                                <br />
                                <br />
                                You’ve got this! 💪
                            </div>
                        </div>
                        <Button
                            className={`tasks__next-btn`}
                            style={{ width: '487px', margin: '0 57px' }}
                            type="primary"
                            onClick={() => {
                                if (isLoadingSendTask) return;
                                sendTaskFunc();
                            }}
                        >
                            {isLoadingSendTask ? <Loader /> : 'Got it'}
                        </Button>
                    </div>
                )
            ) : (
                ''
            )}
        </Modal>
    );
};
