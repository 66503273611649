import DatePicker from 'react-datepicker';
import { useRef, useState } from 'react';
import calendar from '../../assets/calendar-chedule.svg';
import arrow from '../../assets/Rounded-arrow.svg';
import arrowdown from '../../assets/arrow.svg';
import 'react-datepicker/dist/react-datepicker.css';
import { Input } from 'antd';
import { useClickOutside } from '../../hooks/useClickOutSide';
import moment from 'moment';
import close from '../../assets/Close.svg';
import { CalendarSvg } from '../Icons/CalendarIcon';

export const DatePickerInput = ({
    value,
    required,
    placeholder,
    floatLabel,
    disabled,
    onChange,
    filterDisabled,
    isImage = true,
    allowClear = true,
    dateFormat = 'yyyy-MM-DD'
}) => {
    const [focus, setFocus] = useState(false);
    const [openDatepicker, setOpenDatepicker] = useState(false);
    const ref = useRef();

    useClickOutside(ref, () => setOpenDatepicker(false));

    if (!placeholder) placeholder = floatLabel;

    const isOccupied = focus || value;

    const labelClass = isOccupied ? 'label as-label' : `label as-placeholder-meeting ${isImage ? '' : 'withoutimage'}`;

    const requiredMark = required ? <span className="red">*</span> : null;

    const isDayDisabled = (date) => {
        const isSunday = (date) => {
            return date.getDay() === 0 || date.getDay() === 6;
        };

        return !isSunday(date);
    };

    return (
        <div ref={ref}>
            <Input
                className={`input-datepicker ${isImage ? '' : 'noImage'}`}
                onClick={() => setOpenDatepicker((prev) => !prev)}
                style={openDatepicker ? { border: '1px solid #001965', cursor: 'pointer' } : { cursor: 'pointer' }}
                value={value ? moment(value).format(dateFormat) : null}
                disabled={disabled}
            />
            {value ? (
                allowClear ? (
                    <img
                        src={close}
                        className={`arrow-datepicker ${isImage ? '' : 'rigth'}`}
                        onClick={() => onChange(null)}
                    />
                ) : (
                    <></>
                )
            ) : (
                <img
                    src={arrowdown}
                    className={`arrow-datepicker ${openDatepicker ? 'rotate' : ''}`}
                    onClick={() => setOpenDatepicker((prev) => !prev)}
                />
            )}

            {isImage ? (
                <CalendarSvg
                    fill={disabled ? '#808CB2' : '#001965'}
                    className={`calendar-image ${value ? 'bottom' : ''} `}
                />
            ) : (
                ''
            )}
            {openDatepicker && (
                <div className="calendar-wrapper">
                    <DatePicker
                        inline
                        className="datepicker input"
                        placeholder=""
                        selected={value ? new Date(value) : null}
                        onChange={(date) => {
                            onChange(date);
                            setOpenDatepicker(false);
                        }}
                        filterDate={filterDisabled ? filterDisabled : isDayDisabled}
                        startDate={new Date(new Date().setDate(new Date().getDate() + 1))}
                        dateFormat={dateFormat}
                        showPopperArrow={true}
                        minDate={new Date(new Date().setDate(new Date().getDate() + 1))}
                        renderCustomHeader={({
                            date,
                            changeYear,
                            changeMonth,
                            decreaseMonth,
                            increaseMonth,
                            prevMonthButtonDisabled,
                            nextMonthButtonDisabled
                        }) => (
                            <div className="current-month">
                                <img src={arrow} onClick={decreaseMonth} style={{ cursor: 'pointer' }} />
                                <div className="current-month__month">
                                    {date?.toLocaleString('en-US', { month: 'long', year: 'numeric' })}
                                </div>

                                <img
                                    src={arrow}
                                    style={{ transform: 'rotate(180deg)', cursor: 'pointer' }}
                                    onClick={increaseMonth}
                                />
                            </div>
                        )}
                    />
                </div>
            )}
            <label className={labelClass}>
                {isOccupied ? floatLabel : placeholder} {requiredMark}
            </label>
        </div>
    );
};
