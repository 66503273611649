import { useState, FC, useEffect } from 'react';
import { useRestartPhaseMutation, useSendTaskMutation, useUpdateTaskDeadlineMutation } from '../../api/tenders';
import { tenderDataProps } from '../../pages/TenderViewPage/types';
import { Button, Tooltip, message, Modal } from 'antd';
import { useAppSelector } from '../../hooks';
import AwardWelcome from '../../assets/AwardWelcome.svg';
import Notif from '../../assets/Notif.svg';
import arrow from '../../assets/arrow-right-white.svg';
import calendar from '../../assets/calendar-chedule.svg';
import attention from '../../assets/attention.svg';
import edit from '../../assets/editAdminIcon.svg';
import moment from 'moment';
import { TaskDeadlineModal } from '../Modals/TaskDeadline';
import { AwardSupplyAgreement } from './AwardPhaseComponents/AwardSupplyAgreement';
import { ContersignedAgreement } from './AwardPhaseComponents/ContersignedAgreement';
import { CompanyDocumentation } from './AwardPhaseComponents/CompanyDocumentation';
import { CloseTenderForm } from './AwardPhaseComponents/CloseTenderForm';
import { SupplierReportCard } from './EvaluatePhaseComponent/SupplierReportCard';
import { AllTasksCompleted } from './AllTasksCompleted';
import { SupplierListNew } from './DesignPhaseComponents/SupplierListNew';
import i from '../../assets/i.svg';
import restartPhaseImage from '../../assets/restartPhaseBtn.svg';
import { Loader } from '../Loader/Loader';
import './phases.scss';
import { CompletionResultModal } from '../Modals/CompletionResultModal';

interface PropsPhase {
    data: tenderDataProps;
    setTrigger: React.Dispatch<React.SetStateAction<boolean>>;
    setConfirmModal: React.Dispatch<React.SetStateAction<boolean>>;
}

export const AwardPhase: FC<PropsPhase> = ({ data, setTrigger, setConfirmModal }) => {
    const { role } = useAppSelector((state) => state.profileReducer);
    const [screenWidth, setScreenWidth] = useState<number>(window.screen.width);
    function handleResize() {
        setScreenWidth(window.screen.width);
    }
    window.addEventListener('resize', handleResize);
    const [deadlineModal, setDeadlineModal] = useState(false);
    const [SelectedDate, setSelectedDate] = useState<Date | string>(new Date());

    const [updateDeadline, { isLoading }] = useUpdateTaskDeadlineMutation();
    const [restartPhase, { data: dataRestart, isSuccess, isLoading: isLoadingRestart }] = useRestartPhaseMutation();
    const [isRestartModal, setIsRestartModal] = useState(false);
    const [openInfoModal, setOpenInfoModal] = useState(false);
    const [isComplationModal, setIsCompetionModal] = useState(false);

    const updateDeadlineFunc = async () => {
        try {
            const formData = {
                id: data.id,
                data: { deadline_at: moment(SelectedDate).format('YYYY-MM-DD') }
            };
            await updateDeadline(formData).unwrap();
            await setDeadlineModal(false);
            await setTrigger((prev: boolean) => !prev);
        } catch {}
    };

    const [sendTask, { isError: isErrorSendTask, isSuccess: isSuccessSendTask, isLoading: isLoadingSendTask }] =
        useSendTaskMutation();

    const [messageApi, contextHolder] = message.useMessage();

    const sendTaskFunc = async () => {
        const formData = {
            id: data?.id
        };
        try {
            await sendTask(formData).unwrap();
        } catch {}
    };

    useEffect(() => {
        if (isErrorSendTask) {
            messageApi.error('Something went wrong, please try again');
        }
        if (isSuccessSendTask) {
            setTrigger((prev) => !prev);
            setOpenInfoModal(false);
        }
    }, [isErrorSendTask, isSuccessSendTask]);

    useEffect(() => {
        if (isSuccess) {
            messageApi.success(dataRestart.message);
            setIsRestartModal(false);
            setTrigger((prev) => !prev);
        }
    }, [isSuccess]);

    const setdTaskTooltipText = () => {
        const firstTask = data?.phase_tasks?.[0];
        if (firstTask?.action === 'award_suppliers') {
            return 'To send this task, you need complete all Task Activities';
        }
        if (firstTask?.action === 'award_supply_agreement' && data?.phase_tasks?.length === 1) {
            if (!firstTask?.links?.suppliers?.[0]?.link) {
                return 'To send this task, you need complete all Task Activities';
            }
        }
        if (data?.phase_tasks?.length > 1) {
            return 'To send this task, you need complete all Task Activities';
        }
    };
    const sendTaskButtonText = () => {
        const firstTask = data?.phase_tasks?.[0];
        if (firstTask?.action === 'award_suppliers') {
            if (firstTask.status === 'pending_tr') {
                return 'Send All Agreements';
            }
            if (firstTask.status === 'need_resend_tr') {
                return 'Next Task';
            }
        }
        if (firstTask?.action === 'award_supply_agreement' && data?.phase_tasks?.length === 1) {
            if (firstTask.approved || firstTask.declined) {
                return 'Next Task';
            } else {
                return 'Send Task';
            }
        }
        if (data?.phase_tasks?.length > 1) {
            return 'Send Task';
        }
    };

    return (
        <>
            {contextHolder}
            <div className={`tendermain__block tasks`}>
                {role === 'tender_representative' && (
                    <div className="tasks__header define">
                        <div className="count-wrapper">
                            Task Centre
                            <div className="count">{data?.phase_task_count}</div>
                        </div>

                        {(data?.phase_tasks?.[0]?.status === 'pending_tr' ||
                            data?.phase_tasks?.[0]?.status === 'need_resend_tr' ||
                            data?.phase_tasks?.[0]?.status === 'completed') &&
                            data?.phase_tasks?.[3]?.status !== 'pending_sa' &&
                            data?.phase_name !== 'Closed' && (
                                <div className="tasks__deadline-wrapper">
                                    <img src={attention} />
                                    {screenWidth > 500 ? 'Task' : ''} Deadline -{' '}
                                    {moment(data?.phase_deadline_at).format('YYYY-MM-DD')}
                                </div>
                            )}
                    </div>
                )}
                {role === 'super_admin' && (
                    <div className="tasks__header define">
                        <div className="count-wrapper">
                            Task Centre
                            <div className="count">{data?.phase_task_count}</div>
                        </div>

                        {data?.phase_tasks?.[0]?.status === 'pending_sa' && data?.phase_name !== 'Closed' && (
                            <div className="tasks__deadline-wrapper">
                                <img src={attention} />
                                {screenWidth > 500 ? 'Task' : ''} Deadline -{' '}
                                {moment(data?.phase_deadline_at).format('YYYY-MM-DD')}
                            </div>
                        )}
                    </div>
                )}
                {role === 'school_user' && (
                    <div className="tasks__header design">
                        <div className="count-wrapper">
                            Task Centre
                            <div className="count">{data?.phase_task_count}</div>
                        </div>
                        {data?.phase_tasks?.[0]?.status === 'pending_su' && data.phase_tasks.length === 1 && (
                            <div className="tasks__deadline-wrapper">
                                <img src={attention} />
                                {screenWidth > 500 ? 'Task' : ''} Deadline -{' '}
                                {moment(data?.tasks_deadline_at).format('YYYY-MM-DD')}
                            </div>
                        )}
                    </div>
                )}

                <div className="tasks__list">
                    {role !== 'school_user' &&
                        data.status !== 'archive' &&
                        data?.phase_tasks?.[0]?.action !== 'award_suppliers' &&
                        data?.phase_tasks?.[0]?.status !== 'completed' &&
                        data?.phase_tasks?.[0]?.status !== 'pending_sa' &&
                        data?.tasks_deadline_at && (
                            <div className="tasks__info-wrap info-wrap" style={{ margin: '0 0 16px 0' }}>
                                <div className="info-wrap__deadline">Task Deadline for School User</div>
                                <div className="info-wrap__status">
                                    {moment(data?.tasks_deadline_at).format('YYYY-MM-DD')}
                                </div>
                            </div>
                        )}
                    {data.phase_tasks.length ? (
                        <>
                            {role === 'school_user' &&
                                data?.phase_tasks?.find((elem) => elem.action === 'award_supply_agreement')?.status ===
                                    'completed' && <AllTasksCompleted />}
                            <SupplierListNew data={data} messageApi={messageApi} setTrigger={setTrigger} role={role} />
                            {data?.phase_tasks?.find((elem) => elem.action === 'award_supply_agreement') &&
                            role === 'school_user' &&
                            data?.phase_tasks?.find((elem) => elem.action === 'award_supply_agreement')?.status !==
                                'completed' ? (
                                <div className="note" style={{ margin: '-5px 0 15px 0' }}>
                                    <img src={i} />
                                    <div>
                                        <b>Important Note:</b> This is a draft of the final supply agreement with
                                        mark-ups. It is not ready for signature. Once you're happy with it, approve it
                                        in the system, and we will send the final version for electronic signature.
                                    </div>
                                </div>
                            ) : (
                                <></>
                            )}
                            <SupplierReportCard
                                data={data}
                                messageApi={messageApi}
                                setTrigger={setTrigger}
                                role={role}
                            />
                            <AwardSupplyAgreement
                                data={data}
                                messageApi={messageApi}
                                setTrigger={setTrigger}
                                role={role}
                            />
                            <ContersignedAgreement
                                data={data}
                                messageApi={messageApi}
                                setTrigger={setTrigger}
                                role={role}
                            />
                            <CompanyDocumentation
                                data={data}
                                messageApi={messageApi}
                                setTrigger={setTrigger}
                                role={role}
                            />
                            <CloseTenderForm data={data} messageApi={messageApi} setTrigger={setTrigger} role={role} />
                        </>
                    ) : (
                        <>
                            {role === 'school_user' && !data.tasks_deadline_at && (
                                <div className="noTasks">
                                    <img src={AwardWelcome} />
                                    <div className="noTasks__title">Welcome to the Award Phase!</div>
                                    <div className="noTasks__subtitle">
                                        Watch this space - new tasks for you will be posted here as they become
                                        available.
                                    </div>
                                </div>
                            )}
                            {role === 'school_user' && data.tasks_deadline_at && (
                                <div className="noTasks">
                                    <img src={Notif} />
                                    <div className="noTasks__title">Negotiations are currently in progress</div>
                                    <div className="noTasks__subtitle" style={{ width: '395px' }}>
                                        The results of these discussions will be published in the Supplier Report Card
                                        on
                                    </div>
                                    <div className="noTasks__date">
                                        <img src={calendar} className="noTasks__image" />{' '}
                                        {moment(data.tasks_deadline_at).format('DD MMMM, YYYY')}
                                    </div>
                                </div>
                            )}
                        </>
                    )}
                </div>

                {role === 'tender_representative' && (
                    <div className="tasks__btn-wrap">
                        <div style={{ width: '47%' }}>
                            {data?.phase_name === 'Award' && (
                                <div className="tasks__resend withoutBorder" onClick={() => setIsRestartModal(true)}>
                                    <img src={restartPhaseImage} />
                                    Restart Phase
                                </div>
                            )}
                        </div>

                        <Tooltip
                            title={!data?.can_send_task && setdTaskTooltipText()}
                            overlayClassName={` ${
                                !data?.can_send_task && data.phase_tasks?.[0]?.status !== 'pending_su'
                                    ? 'custom-tooltip-disabled'
                                    : 'custom-tooltip'
                            }`}
                        >
                            <Button
                                className={`tasks__next-btn ${data?.can_send_task ? '' : 'disabled'}`}
                                type="primary"
                                style={sendTaskButtonText() === 'Send All Agreements' ? { width: '230px' } : {}}
                                onClick={() => {
                                    if (!data?.can_send_task) {
                                        return;
                                    } else {
                                        if (!isLoadingSendTask) {
                                            if (
                                                data?.phase_tasks?.[0]?.action === 'award_suppliers' &&
                                                data?.phase_tasks?.[0]?.status === 'need_resend_tr'
                                            ) {
                                                setOpenInfoModal(true);
                                            } else {
                                                sendTaskFunc();
                                            }
                                        }
                                    }
                                }}
                            >
                                {sendTaskButtonText()}
                                <img src={arrow} />
                            </Button>
                        </Tooltip>
                    </div>
                )}

                {role === 'school_user' &&
                    data.phase_tasks.length > 0 &&
                    data?.phase_tasks[0].status !== 'need_resend_tr' &&
                    data?.phase_tasks[0].status !== 'completed' &&
                    data.phase_tasks[0].action !== 'award_countersigned_agreement' && (
                        <div className="tasks__btn-wrap">
                            <div></div>

                            <Tooltip
                                title="To send this task, you need to complete all the Task Activities"
                                overlayClassName={` ${
                                    !data?.can_send_task && data.phase_tasks?.[0]?.status !== 'need_resend_tr'
                                        ? 'custom-tooltip-disabled'
                                        : 'custom-tooltip'
                                }`}
                            >
                                <Button
                                    className={`tasks__next-btn ${data?.can_send_task ? '' : 'disabled'}`}
                                    type="primary"
                                    onClick={() => {
                                        if (data?.can_send_task) {
                                            setIsCompetionModal(true);
                                        }
                                    }}
                                >
                                    Submit <img src={arrow} />
                                </Button>
                            </Tooltip>
                        </div>
                    )}
                {role === 'super_admin' &&
                    data.phase_tasks.length > 0 &&
                    data?.phase_tasks[0].status === 'pending_sa' && (
                        <div className="tasks__btn-wrap">
                            <div></div>

                            {data?.phase_tasks[0].approved && !data.phase_tasks[0].declined && (
                                <Button
                                    className={`tasks__next-btn`}
                                    type="primary"
                                    onClick={() => {
                                        if (data?.can_update_phase) {
                                            setConfirmModal(true);
                                        } else {
                                            return;
                                        }
                                    }}
                                >
                                    Close Tender <img src={arrow} />
                                </Button>
                            )}
                            {data?.phase_tasks[0].declined && !data?.phase_tasks[0].approved && (
                                <Button
                                    className={`tasks__next-btn`}
                                    type="primary"
                                    onClick={() => {
                                        if (data?.can_send_task) {
                                            if (!isLoadingSendTask) {
                                                sendTaskFunc();
                                            }
                                        }
                                    }}
                                >
                                    Send Back <img src={arrow} />
                                </Button>
                            )}
                            {!data?.phase_tasks[0].declined && !data?.phase_tasks[0].approved && (
                                <Tooltip>
                                    <Button
                                        className={`tasks__next-btn ${data?.can_send_task ? '' : 'disabled'}`}
                                        type="primary"
                                    >
                                        Send Task <img src={arrow} />
                                    </Button>
                                </Tooltip>
                            )}
                        </div>
                    )}
            </div>
            {deadlineModal && (
                <TaskDeadlineModal
                    openModal={deadlineModal}
                    setOpenModal={setDeadlineModal}
                    SelectedDate={SelectedDate}
                    setSelectedDate={setSelectedDate}
                    updateDeadlineFunc={updateDeadlineFunc}
                    isLoading={isLoading}
                    data={data}
                />
            )}
            {isRestartModal && (
                <Modal
                    className="confirmModal"
                    centered
                    open={isRestartModal}
                    closeIcon={<div style={{ display: 'none', cursor: 'context-menu' }}></div>}
                    width={600}
                    footer={
                        <div style={{ display: 'flex', justifyItems: 'space-between', gap: '16px', width: '100%' }}>
                            <Button key="back" onClick={() => setIsRestartModal(false)} block className="cancel">
                                Cancel
                            </Button>
                            <Button
                                className={`tasks__next-btn`}
                                style={{ width: '100%' }}
                                type="primary"
                                onClick={() => {
                                    if (isLoadingRestart) return;
                                    restartPhase({ tender_id: data?.id });
                                }}
                            >
                                {isLoading || isLoadingSendTask ? <Loader /> : 'Yes'}
                            </Button>
                        </div>
                    }
                >
                    <div className="modal">
                        <div className="modal__header" style={{ height: '125px' }}>
                            <div className="modal__title">
                                Are you sure you want to restart <br />
                                the Award phase?
                            </div>
                        </div>

                        <div className="modal__main main" style={{ padding: '24px 56px 43px 56px' }}>
                            <div className="main__title" style={{ margin: '0 0 6px 0' }}>
                                Restarting will allow you to select which Suppliers to proceed with. The selection is
                                available from Suppliers shortlisted for the Evaluate Gate 4 phase, including those
                                previously awarded. You can choose different Suppliers, even if they were not awarded
                                during the Evaluate Gate 4 phase.
                                <br />
                                <br />
                                This action cannot be undone. Do you want to continue?
                            </div>
                        </div>
                    </div>
                </Modal>
            )}
            {openInfoModal && (
                <Modal
                    className="confirmModal"
                    centered
                    open={openInfoModal}
                    closeIcon={<div style={{ display: 'none', cursor: 'context-menu' }}></div>}
                    width={600}
                    footer={
                        <div style={{ display: 'flex', justifyItems: 'space-between', gap: '16px', width: '100%' }}>
                            <Button
                                className={`tasks__next-btn`}
                                style={{ width: '100%' }}
                                type="primary"
                                onClick={() => {
                                    if (isLoadingSendTask) return;
                                    sendTaskFunc();
                                }}
                            >
                                {isLoadingSendTask ? <Loader /> : 'Sure'}
                            </Button>
                        </div>
                    }
                >
                    <div className="modal">
                        <div className="modal__main main" style={{ padding: '56px' }}>
                            <div className="main__title" style={{ margin: '0 0 6px 0' }}>
                                Please, ensure that an invoice is raised for the Set-Up Fee
                            </div>
                        </div>
                    </div>
                </Modal>
            )}
            {isComplationModal && (
                <CompletionResultModal
                    openModal={isComplationModal}
                    setOpenModal={setIsCompetionModal}
                    data={data}
                    setTrigger={setTrigger}
                />
            )}
        </>
    );
};
